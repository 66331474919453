// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gallery-commercial-js": () => import("./../../../src/pages/gallery/commercial.js" /* webpackChunkName: "component---src-pages-gallery-commercial-js" */),
  "component---src-pages-gallery-landscape-js": () => import("./../../../src/pages/gallery/landscape.js" /* webpackChunkName: "component---src-pages-gallery-landscape-js" */),
  "component---src-pages-gallery-portraits-js": () => import("./../../../src/pages/gallery/portraits.js" /* webpackChunkName: "component---src-pages-gallery-portraits-js" */),
  "component---src-pages-gallery-restoration-js": () => import("./../../../src/pages/gallery/restoration.js" /* webpackChunkName: "component---src-pages-gallery-restoration-js" */),
  "component---src-pages-gallery-schools-js": () => import("./../../../src/pages/gallery/schools.js" /* webpackChunkName: "component---src-pages-gallery-schools-js" */),
  "component---src-pages-gallery-workshops-js": () => import("./../../../src/pages/gallery/workshops.js" /* webpackChunkName: "component---src-pages-gallery-workshops-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

